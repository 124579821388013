*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
}

.main {
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main__wrapper {
  width: 100%;
}

.title {
  width: 100%;
  text-align: center;
  margin: 0 0 40px;
  font-size: 24px;
}

.form {
  width: 100%;
  display: flex;
  align-items: center;
}

.form__input {
  width: calc(100% - 60px);
  height: 36px;
  font-size: 14px;
  border: none;
  outline: none;
  border-bottom: 2px solid rgb(100, 100, 100);
  line-height: 1;
  display: inline-block;
  padding: 0 10px;
  background-color: #fff;
  color: #000;
}

.form__input:focus {
  border-color: #1976D2;
}

.form__button {
  height: 36px;
  border: none;
  background-color: #1976D2;
  color: white;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 2px;
  font-weight: 400;
  transition: .3s cubic-bezier(.25,.8,.5,1),color 1ms;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  margin-left: 10px;
}

.form__button:hover {
  background-color: #1565c0;
}

.result {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.result__code {
  width: calc(50% - 10px);
  padding: 10px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  background-color: #eeeeef;
  overflow-x: auto;
}

.result__list {
  width: calc(50% - 10px);
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  background-color: #eeeeef;
  color: #252525;
  padding: 10px;
}

.result__item {
  margin-bottom: 10px;
}

.result__key {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}

.result__input {
  display: inline-block;
  width: 100%;
  border: 0;
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(100, 100, 100);
  line-height: 1;
  display: inline-block;
  background-color: transparent;
  color: #1976D2;
}

.result__input:focus {
  border-color: #1976D2;
}

.result__ul {
  list-style: none;
  padding: 0;
}

.result__code pre {
  margin: 0;
}

.example {
  margin-top: 5px;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  background-color: #eeeeef;
}

.error {
  margin-top: 5px;
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
  background-color: #ff9800;
  color: white;
}

.error a {
  color: white;
  text-decoration: underline;
}

.button-holder {
  margin-top: 10px;
}