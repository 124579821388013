*, :before, :after {
  box-sizing: border-box;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
}

.main {
  flex-wrap: wrap;
  align-items: center;
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
}

.main__wrapper {
  width: 100%;
}

.title {
  text-align: center;
  width: 100%;
  margin: 0 0 40px;
  font-size: 24px;
}

.form {
  align-items: center;
  width: 100%;
  display: flex;
}

.form__input {
  color: #000;
  background-color: #fff;
  border: none;
  border-bottom: 2px solid #646464;
  outline: none;
  width: calc(100% - 60px);
  height: 36px;
  padding: 0 10px;
  font-size: 14px;
  line-height: 1;
  display: inline-block;
}

.form__input:focus {
  border-color: #1976d2;
}

.form__button {
  color: #fff;
  cursor: pointer;
  background-color: #1976d2;
  border: none;
  border-radius: 2px;
  height: 36px;
  margin-left: 10px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 400;
  transition: all .3s cubic-bezier(.25, .8, .5, 1), color 1ms;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.form__button:hover {
  background-color: #1565c0;
}

.result {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
}

.result__code {
  background-color: #eeeeef;
  width: calc(50% - 10px);
  padding: 10px;
  overflow-x: auto;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.result__list {
  color: #252525;
  background-color: #eeeeef;
  width: calc(50% - 10px);
  padding: 10px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
}

.result__item {
  margin-bottom: 10px;
}

.result__key {
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
}

.result__input {
  color: #1976d2;
  background-color: #0000;
  border: none;
  border-bottom: 1px solid #646464;
  outline: none;
  width: 100%;
  line-height: 1;
  display: inline-block;
}

.result__input:focus {
  border-color: #1976d2;
}

.result__ul {
  padding: 0;
  list-style: none;
}

.result__code pre {
  margin: 0;
}

.example {
  background-color: #eeeeef;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
}

.error {
  color: #fff;
  background-color: #ff9800;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
}

.error a {
  color: #fff;
  text-decoration: underline;
}

.button-holder {
  margin-top: 10px;
}
/*# sourceMappingURL=index.5f952e30.css.map */
